import React from 'react'
import PropTypes from 'prop-types';
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from "../components/AppLayout";
import PageInfo from '../components/PageInfo'
import { graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { AccentSection } from './index'
import AnchorLink from '../components/shared/AnchorLink'
import Typography from '@material-ui/core/Typography'
import SignUpAndDemoButtons from '../components/SignUpAndDemoButtons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RouterLink from '../components/shared/RouterLink'
import Brand from '../components/shared/Brand'
import Spacing from '../reused-libraries/Spacing'
import { FreelanceBarsBg } from '../components/shared/display/AngledBarsBg'
import HeaderNavigation from '../components/page/HeaderNavigation'
import { TrustSignals } from '../components/common/TrustSignals'
import { ProductVideo } from '../components/common/ProductVideo'
import { buildHowToExtra, LandingHowTo } from '../components/common/LandingHowTo'
import { FeatureCard } from '../components/common/FeatureCard'
import Grid from '@material-ui/core/Grid'
import { TWalshTestimonial } from '../components/quotes/Quotations'
import { ForceLightTheme } from '../reused-libraries/materialTheme'

const useFreelanceTimeTrackingStyles = makeStyles(theme => ({
	mainTagline: {
		backgroundColor: "#dee2e6", // gray-300
		padding: "3rem 0",
		marginBottom: theme.spacing(6),
	},
	afterBarsBg: {
		position: "relative",
		backgroundColor: theme.palette.background.default,
	},
	section: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
	},
}), {name: "FreelanceTimeTracking"});

export default function FreelanceTimeTracking(props) {
	let {data} = props;
	let {productName} = useSiteMetadata();

	const classes = useFreelanceTimeTrackingStyles();

	return (
		<AppLayout excludeHeaderNav>
			<PageInfo
				title={"Online Time Tracking For Freelancers | Best Free Hourly Timesheet App"}
				description={productName + " freelance timesheet app makes client management a breeze. Easily take care of your hours, expenses, and invoicing all in one place. Sign up for free."}
			/>

			<FreelanceBarsBg>
				<HeaderNavigation logoVariant="whiteout"/>

				<Spacing pt={12}>
					<BenefitsHeadline/>
					<CallToAction/>
					<ForceLightTheme>
						<TrustSignals>
							<TWalshTestimonial/>
						</TrustSignals>
					</ForceLightTheme>
					<ProductVideo
						title="Freelance Time Tracking App Video"
						youTubeVideoId="0JMmosI14Fc"
						description='A brief overview of how to use the "My Time" screen in LogSpace.'
						uploadDate="2020-04-20"
					/>
				</Spacing>
			</FreelanceBarsBg>

			<div className={classes.afterBarsBg}>
				<MainTagline className={classes.mainTagline}/>
				<HowTo className={classes.section} data={data}/>
				<Features className={classes.section} data={data}/>
				{/* Testimonial could also be placed here? */}
				<FAQ className={classes.section}/>
			</div>

		</AppLayout>
	);
}
const useBenefitsHeadlineStyles = makeStyles(theme => ({
	mainTagline: {
		fontSize: theme.typography.pxToRem(35),
		[theme.breakpoints.up('md')]: {
			fontSize: theme.typography.pxToRem(38.5),
		},
	},
}), {name: "BenefitsHeadline"});

function BenefitsHeadline(props) {
	const classes = useBenefitsHeadlineStyles();
	return (
		<header className="container-fluid px-sm-5 text-center">
			<Typography variant="h1" gutterBottom className={classes.mainTagline}>
				Free Online Time Tracking For Freelancers &amp; Independent Contractors - What Is The Best Timesheet App For Freelancers?
			</Typography>
			{/*<Typography component="h2" variant="h6" gutterBottom>*/}
			{/*	Kapow Blamo Whammy*/}
			{/*</Typography>*/}
			<Typography variant="body2">
				<Brand/> time tracker is a great tool for workers that manage clients.<br/>
				Use it to log your billable hours and expenses, download reports, and send invoices to your clients.
			</Typography>
		</header>
	);
}
BenefitsHeadline.propTypes = {

};

const useCallToActionStyles = makeStyles(theme => ({
	ctaContainer: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
	},
}), {name: "CallToAction"});

function CallToAction(props) {
	const classes = useCallToActionStyles();
	return (
		<section className={classes.ctaContainer}>
			<div className="container-fluid">
				<SignUpAndDemoButtons
					centered
					jumbo
					webLinkText="Start Your Free Account"
					// customNote="Free Plan Available"
				/>
			</div>
		</section>
	);
}
CallToAction.propTypes = {

};

function MainTagline(props) {
	let {className} = props;
	let {productName} = useSiteMetadata();
	return (
		<section className={className}>
			<div className="container-fluid">
				<h2>
					Simple Freelance Time Tracking With Invoicing and Expenses
				</h2>
				<p>
					Hey freelancers! We've got an eye out for making your job easier because we're
					one of you. As a contractor, you don't want to have any unnecessary overhead
					when it's time to get paid.
				</p>
				<p>
					If you're <RouterLink to="/features/invoicing/">sending invoices</RouterLink> to
					clients, LogSpace can automate them for you. Just
					the way it should be! Once you set a recurrence for each client, LogSpace will
					create each invoice when it's time. You'll have a chance to glance over each
					invoice before sending them to your clients.
				</p>
				<p>
					As you're working on projects, it's one tap to start logging time. Use our
					mobile app if that's more convenient.
				</p>
				<p>
					We know there's also a good chance you've got some expenses to manage whether
					you're billing for them or not. So we've got you covered with expense tracking
					too.
				</p>
				<p>
					Put all that and more together, and you've got LogSpace. It's a simple
					all-in-one freelance time tracker with expenses and invoicing. We built LogSpace
					for independent contractors to manage their day-to-day activities. And it's
					unobtrusive, so it'll stay out of your way while you do what you do best.
				</p>
			</div>
		</section>
	);
}
MainTagline.propTypes = {
	className: PropTypes.string,
};

function buildHowToNumberedItems(productName, data) {
	return [
		{
			// TODO: move link to siteData
			title: (<React.Fragment>Start using your {productName} time tracker with a free account.</React.Fragment>),
			content: null,
			img: null,
		},
		{
			title: 'Go to the "My Time" screen',
			content: (
				<ul>
					<li>When you log in, you'll be looking at the "My Time" screen. If you aren't, select it from the navigation menu.</li>
				</ul>
			),
			img: {
				imgData: data.freelanceMenu.childImageSharp.gatsbyImageData,
				alt: "Selecting my time on the menu",
			},
		},
		{
			title: "Clock in",
			content: (
				<ul>
					<li>When you're ready to start working, press the "Clock In" button. It's the one with the play icon.</li>
				</ul>
			),
			img: {
				imgData: data.freelanceClockIn.childImageSharp.gatsbyImageData,
				alt: "Pressing the clock in button",
			},
		},
		{
			title: "Enter work details",
			content: (
				<ul>
					<li>When you clock in, your new time log will appear at the top of the screen.</li>
					<li>Here you can adjust the details and add notes about what you are working on.</li>
					<li>Press the "Save" button to save any changes you made.</li>
				</ul>
			),
			img: {
				imgData: data.freelanceDetails.childImageSharp.gatsbyImageData,
				alt: "Entering time log details",
			},
		},
		{
			title: "Clock out",
			content: (
				<ul>
					<li>When you finish working, press the "Clock Out" button. It has a stop icon.</li>
				</ul>
			),
			img: {
				imgData: data.freelanceClockOut.childImageSharp.gatsbyImageData,
				alt: "Pressing the clock out button",
			},
		},
		{
			title: "Review your time logs",
			content: (
				<ul>
					<li>You can see your finished time log in the "My Time Logs" section. Here you can tap on the time log to view and edit expanded details.</li>
					<li>You can also add time logs for work already completed by pressing the "Add New Time Log" button.</li>
					<li>If you prefer a timesheet interface, you can find "Timesheet" in the navigation menu. There you can enter hours instead of start and stop times.</li>
				</ul>
			),
			img: {
				imgData: data.freelanceTimeTracker.childImageSharp.gatsbyImageData,
				alt: "Reviewing time tracker logs",
			},
		},
	];
}

function HowTo(props) {
	let {className, data} = props;
	let {productName} = useSiteMetadata();
	return (
		<LandingHowTo
			className={className}
			header="Time Tracking for Freelancers Made Easy. Here's How…"
			numberedItems={buildHowToNumberedItems(productName, data)}
			nonNumberedHeader="Once you have saved your time logs, you can also:"
			nonNumberedItems={buildHowToExtra(productName, data)}
		/>
	);
}
HowTo.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object.isRequired,
};

const features = [
	{
		title: "Time Tracking",
		icon: (<FontAwesomeIcon icon={["far", "clock"]}/>),
		description: "With one click, start recording your time. Or if timesheets are a better fit, just enter each duration. You can create time logs for past work too.",
		requiresTeamPlan: false,
	},
	{
		title: "Expenses",
		icon: (<FontAwesomeIcon icon={["fas", "coins"]}/>),
		description: "As you incur expenses, post them to LogSpace. You'll be able to easily track each expense with a date and notes. Choose a currency for your team.",
		requiresTeamPlan: false,
	},
	{
		title: "Clients / Invoicing",
		icon: (<FontAwesomeIcon icon={["fas", "file-invoice"]}/>),
		description: "Manage multiple clients with invoices for your time and expenses. Easily set up a recurrence schedule to automate invoice creation.",
		requiresTeamPlan: true,
	},
	{
		title: "Projects",
		icon: (<FontAwesomeIcon icon={["fas", "briefcase"]}/>),
		description: "As you and your teammates work on various tasks, you can keep them organized in multiple projects.",
		requiresTeamPlan: false,
	},
	{
		title: "Team Members",
		icon: (<FontAwesomeIcon icon={["fas", "users"]}/>),
		description: "Invite others to your team, and set individual permissions. Then you'll be able to see and edit records for your team members.",
		requiresTeamPlan: true,
	},
	{
		title: "Reports & Exports",
		icon: (<FontAwesomeIcon icon={["fas", "table"]}/>),
		description: "View your time and expenses in LogSpace or export them in CSV or Excel. Use your time zone settings to view your data in local time.",
		requiresTeamPlan: false,
	},
	{
		title: "Time Log Imports",
		icon: (<FontAwesomeIcon icon={["fas", "cloud-upload-alt"]}/>),
		description: "If you're moving from another system, import your data as a bulk import from CSV or Excel. LogSpace has a smart importer to recognize many matching fields.",
		requiresTeamPlan: true,
	},
	{
		title: "iPhone and Android Mobile App",
		icon: (<FontAwesomeIcon icon={["fas", "mobile-alt"]}/>),
		description: "As you're moving around, it's always nice to have time tracking quickly at hand. The LogSpace time tracking mobile app is synchronized with your same data as the web app.",
		requiresTeamPlan: false,
	},
	{
		title: "Rounding Modes",
		icon: (<FontAwesomeIcon icon={["fas", "calculator"]}/>),
		description: "Every client has unique needs, and time rounding is no exception. Round your time for each client, such as in 6 or 15 minute increments.",
		requiresTeamPlan: false,
	},
];

const useFeaturesStyles = makeStyles(theme => ({
	accentSectionHeading: {
		fontSize: theme.typography.pxToRem(45),
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.pxToRem(35),
		},
		marginBottom: theme.spacing(4),
	},
	footnote: {
		textAlign: "center",
	},
}), {name: "Features"});

function Features(props) {
	const {className, data} = props;
	const classes = useFeaturesStyles();
	return (
		<AccentSection className={className}>
			<Typography variant="h2" className={classes.accentSectionHeading}>Freelance Time Tracking Features</Typography>

			<Grid container spacing={4}>
				{features.map(({title, icon, description, requiresTeamPlan}, idx) => (
					<Grid key={idx} item xs={12} md={6} lg={4}>
						<FeatureCard
							title={title}
							icon={icon}
							ribbon={!requiresTeamPlan ? null : (<React.Fragment>Team<sup>*</sup></React.Fragment>)}
						>
							{description}
						</FeatureCard>
					</Grid>
				))}
			</Grid>

			<Spacing mt={6} className={classes.footnote}>
				<Typography variant="body2">* Certain features require a paid team plan</Typography>
			</Spacing>

			{/*TODO: Mention hourly rates?*/}
		</AccentSection>
	);
}
Features.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object.isRequired,
}

const frequentlyAskedQuestions = [
	{
		question: 'Does LogSpace offer free time tracking software for freelancers?',
		answer: (
			<React.Fragment>
				Yes! You don't have to pay us. Get Started when you're ready.
				If you want some more advanced features, LogSpace offers a paid upgrade as well.
			</React.Fragment>
		),
	},
	{
		question: 'How do I track my consulting time?',
		answer: 'Enter your email to sign up at LogSpace. Then you can immediately hit a button to clock in!',
	},
	{
		question: 'How do I track my hours at work?',
		answer: 'LogSpace is a one-stop-shop for invoicing as well as time and expense tracking. Start tracking your hours with a single tap in our web or mobile app.',
	},
	{
		question: 'How do I track freelance hours?',
		answer: 'As a freelancer, you might be working on more than one project. Hit one button to clock in, and change the project as needed. LogSpace can automatically add your hours to each recurring invoice too.',
	},
	{
		question: 'How do you track time in a project?',
		answer: "LogSpace remembers what project you worked on last. When you clock back in, you'll be right back on that project. Or pick one of your other projects.",
	},
	{
		question: 'How do I log my time?',
		answer: 'There are three ways. You can clock in and out as you start and stop working. Or you can create time logs by entering exact start and stop times. Finally, you can use the timesheet to enter hours.',
	},
	{
		question: 'Do you have a web app for timesheets?',
		answer: (
			<React.Fragment>
				Yes we have <RouterLink to="/timesheet-app/">timesheets</RouterLink> available in our web app, and they are available as part of the Free Plan.  Our timesheets allow you to add hours worked using a familiar week-by-week view.
			</React.Fragment>
		),
	},
];

function FAQ(props) {
	const {className} = props;
	return (
		<section className={className}>
			<div className="container-fluid">
				<Typography variant="h2" gutterBottom>FAQ</Typography>

				{frequentlyAskedQuestions.map(({question, answer}, idx) => (
					<React.Fragment key={idx}>
						<Typography component="h2" variant="h5">{question}</Typography>
						<Typography paragraph variant="body2">{answer}</Typography>
					</React.Fragment>
				))}
			</div>
		</section>
	);
}
Features.propTypes = {
	className: PropTypes.string,
}

export const query = graphql`{
  freelanceClockIn: file(relativePath: {eq: "images/howto_freelance/freelance_clock_in.png"}) {
    childImageSharp { gatsbyImageData(width: 251, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  freelanceClockOut: file(relativePath: {eq: "images/howto_freelance/freelance_clock_out.png"}) {
    childImageSharp { gatsbyImageData(width: 251, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  freelanceDetails: file(relativePath: {eq: "images/howto_freelance/freelance_details.png"}) {
    childImageSharp { gatsbyImageData(width: 251, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  freelanceMenu: file(relativePath: {eq: "images/howto_freelance/freelance_menu.png"}) {
    childImageSharp { gatsbyImageData(width: 251, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  freelanceTimeTracker: file(relativePath: {eq: "images/howto_freelance/freelance_time_tracker.png"}) {
    childImageSharp { gatsbyImageData(width: 251, placeholder: BLURRED, layout: CONSTRAINED) }
  }
  freelanceSave: file(relativePath: {eq: "images/howto_freelance/freelance_save.png"}) {
    childImageSharp { gatsbyImageData(width: 251, placeholder: BLURRED, layout: CONSTRAINED) }
  }
}
`;
