import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { findColorByIndex, radialGradientStyle } from '../themeColors'

const useFeatureCardStyles = makeStyles(theme => ({
	wrapper: {
		position: "relative",
		height: "100%",
		maxWidth: "400px",
		margin: [[0, "auto"]],
	},
	iconContainer: {
		fontSize: "8em",
		textAlign: "center",
		color: findColorByIndex(1),
	},
	title: {
		textAlign: "center",
		marginBottom: theme.spacing(2),
	},
	ribbon: {
		position: "absolute",
		top: theme.spacing(2),
		right: 0,
		...radialGradientStyle(theme, "#a00", "#f00"),
		padding: [[theme.spacing(0.75), theme.spacing(2)]],
		borderRadius: [[theme.shape.borderRadius, 0, 0, theme.shape.borderRadius]]
	},
}), {name: "FeatureCard"});

export function FeatureCard(props) {
	let {title, icon, ribbon, children} = props;
	const classes = useFeatureCardStyles();
	return (
		<Card elevation={8} className={classes.wrapper}>
			<div className={classes.iconContainer}>
				{icon}
			</div>
			<CardContent>
				<Typography variant="h3" className={classes.title}>{title}</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					{children}
				</Typography>
			</CardContent>
			{ribbon && (<Typography variant="body2" component="strong" className={classes.ribbon}>{ribbon}</Typography>)}
		</Card>
	);
}
FeatureCard.propTypes = {
	/** The feature title */
	title: PropTypes.string.isRequired,
	/** An icon to represent the feature */
	icon: PropTypes.any,
	/** Optional content to show in a ribbon */
	ribbon: PropTypes.any,
	/** The feature description */
	children: PropTypes.any,
};
