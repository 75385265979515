import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Typography from '@material-ui/core/Typography'

const useQuotationStyles = makeStyles(theme => ({
	quoteMark: {
		marginRight: theme.spacing(2),
	},
	quote: {
		textAlign: "left",
		fontWeight: 300,
		fontStyle: "italic",
		marginBottom: "1em",
	},
	quoteAttribution: {
		textAlign: "right",
	},
}), {name: "Quotation"});

function Quotation(props) {
	const {attributedTo, children} = props;
	const classes = useQuotationStyles();
	return (
		<>
			<div className="d-flex">
				<FontAwesomeIcon icon="quote-left" size="2x" className={classes.quoteMark}/>
				<Typography component="div" variant="body2" className={classes.quote}>
					{children}
				</Typography>
			</div>
			<Typography component="div" variant="body2" className={classes.quoteAttribution}>
				&mdash; {attributedTo}
			</Typography>
		</>
	);
}
Quotation.propTypes = {
	/** Who is being attributed for this quote? */
	attributedTo: PropTypes.any.isRequired,
	/** The quote */
	children: PropTypes.any.isRequired,
};

export function TWalshTestimonial(props) {

	// WARNING: this component contains a quote.  Take care not to alter the quote or citation.

	return (
		<Quotation
			attributedTo="T. Walsh, Personal Assistant"
		>
			LogSpace helps me keep accurate logs by tracking my work time down to the minute
			with the press of one button, and allows me to type notes, edit logs, and view
			my history all from my phone! I am able to enter my expenses and generate my
			invoice with just a few steps. And best of all, LogSpace does all of my
			calculations for me!
		</Quotation>
	);
}
